.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    svg {
      width: 100%;
      height: auto;
      bottom: 0;
      transform: rotateZ(30deg) !important;
      transform: translateY(-101%) rotateZ(30deg) !important;
    }
  
    .solid-logo {
      position: unset;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      transform: rotateZ(30deg) !important;
      z-index: 1;
      @media screen and (max-width: 1000px) {
        position: unset;
      }
    }

    @media screen and (max-width: 1000px) {
      z-index: 0;
      width: 200px;
      height: 300px;
      opacity: 0;
      position: unset;
      left: 10%;
      top: 5%;
      right: 0;
      bottom: 0;
      margin: 0;
    }

  }
  
  .svg-container {
    stroke: #ffd700;
    stroke-width: 10;
  }